
import Vue from 'vue';
import utils from '../../util';
import { zonedTimeToUtc } from 'date-fns-tz';
import debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'scheduledReportsLog',
  data: (): {
    data: any; // eslint-disable-line
    search: string;
    loading: boolean;
    page: number;
    limit: number;
    totalLogs: number;
    totalPages: number;
    headers: Array<object>;
  } => ({
    data: [],
    search: '',
    loading: true,
    page: 1,
    limit: 15,
    totalLogs: 0,
    totalPages: 0,
    headers: [
      {
        text: 'Result (Sent or Error)',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Agency',
        value: 'agencyName',
        sortable: true,
      },
      {
        text: 'Advertiser',
        value: 'advertiser',
        sortable: true,
      },
      {
        text: 'Report Name',
        value: 'reportName',
        sortable: true,
      },
      {
        text: 'Scheduled By',
        value: 'createdBy',
        sortable: true,
      },
      {
        text: 'Date/Time',
        value: 'dateTime',
        sortable: true,
      },
      {
        text: 'Email Recipients',
        value: 'recipients',
        sortable: true,
      },
    ],
  }),
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
  mounted() {
    this.getScheduledReportsLog(this.limit, 0, this.search);
  },
  created() {
    // Create a debounced version of the search function with a longer delay
    this.debouncedSearch = debounce(this.fetchData, 500);
  },
  methods: {
    async getScheduledReportsLog(limit: number, offset: number, search: string): Promise<void> {
      this.loading = true;

      await this.$store
        .dispatch('getScheduledReportsLog', { limit, offset, search })
        .then(response => {
          if (!response) {
            console.log('getScheduledReportsLog bad response', response);
            return;
          }
          this.totalLogs = response.paginationInfo.totalObjects;
          this.totalPages = response.paginationInfo.totalPages;
          this.page = response.paginationInfo.currentPage + 1;

          this.data = response.logs.map(item => {
            item['agencyName'] = item.additional.ParentAdvertiser.AgencyPartner;
            item['advertiser'] = item.additional.ParentAdvertiser.Name;
            item.dateTime = zonedTimeToUtc(utils.normalizeDate(item.endDate), 'UTC');
            return item;
          });

          this.loading = false;
        })
        .catch((error: Error) => {
          this.loading = false;
          console.error('getScheduledReportsLog error', error);
          return;
        })
        .finally(() => {
          this.pageChangeInProgress = false; // Reset the flag after the request completes
        });
    },
    fetchData() {
      if (this.pageChangeInProgress) return;
      this.pageChangeInProgress = true;
      this.getScheduledReportsLog(this.limit, 0, this.search);
    },
    debouncedSearch() {
      this.page = 1;
      this.fetchData();
    },
    handleInputBlur() {
      this.debouncedSearch.cancel();
      this.fetchData();
    },
    redirectToReport(item: any) {
      this.$router.push(`/reportsadmin/report?reportId=${item.reportId}`);
    },
    onPageChange(newPage) {
      if (this.pageChangeInProgress) return; // Prevent double execution

      this.pageChangeInProgress = true; // Set the flag to prevent further execution

      const currentPage = newPage.page;
      const itemsPerPage = newPage.itemsPerPage;

      if (typeof currentPage === 'number') {
        this.page = currentPage;
        this.limit = itemsPerPage;
        const offset = (currentPage - 1) * this.limit;
        this.getScheduledReportsLog(this.limit, offset, this.search);
      }
    },
  },
});
